import React from "react"
import PropTypes from "prop-types"
import styles from "./styles.module.scss"

const Centerer = ({ children }) => {
  return <div className={styles.centerer}>{children}</div>
}

Centerer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Centerer
