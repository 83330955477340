import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import styles from "./styles.module.scss"
import { Link } from "gatsby"

const Button = ({ children, onClick, href, size }) => {
  const buttonClass = classnames(styles.button, {
    [styles[`button${size && size.toUpperCase()}`]]: size,
  })

  if (href) {
    return (
      <Link to={href} className={buttonClass}>
        {children}
      </Link>
    )
  } else if (onClick) {
    return (
      <button onClick={onClick} className={buttonClass}>
        {children}
      </button>
    )
  }
}

Button.defaultProps = {
  onClick: null,
  href: null,
  size: "m",
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
  size: PropTypes.oneOf(["m", "l"]),
}

export default Button
