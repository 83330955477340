import React from "react"
import { graphql } from "gatsby"

import Page from "../components/Page"
import Centerer from "../components/Centerer"
import Button from "../components/Button"
import List from "../components/List"
import ListItem from "../components/List/ListItem"

const IndexPage = ({ data }) => {
  return (
    <Page>
      <Centerer>
        <h1>Moichigo!</h1>

        <h2>Lessons</h2>

        <List>
          {data.allMarkdownRemark.edges.map(edge => {
            const { slug, pageTitle } = edge.node.fields
            return (
              <ListItem key={slug}>
                <Button href={slug}>{pageTitle}</Button>
              </ListItem>
            )
          })}
        </List>
      </Centerer>
    </Page>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          fields {
            slug
            pageTitle
          }
        }
      }
    }
  }
`
