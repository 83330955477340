import React from "react"
import PropTypes from "prop-types"
import styles from "./styles.module.scss"

const Page = ({ children }) => {
  return <div className={styles.page}>{children}</div>
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page
