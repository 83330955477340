import React from "react"
import PropTypes from "prop-types"
import styles from "./styles.module.scss"

const ListItem = ({ children }) => {
  return <li className={styles.listItem}>{children}</li>
}

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ListItem
