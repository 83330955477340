import React from "react"
import PropTypes from "prop-types"
import styles from "./styles.module.scss"

const List = ({ children }) => {
  return <ul className={styles.list}>{children}</ul>
}

List.propTypes = {
  children: PropTypes.node.isRequired,
}

export default List
